<template>
  <footer
    id="footer"
    class="flex flex-col items-center w-full text-white bg-white pt-6 relative z-[3]"
  >
    <!-- Slider -->
    <!-- <div class="slider">
      <div
        class="slide-track"
        :style="`width:${settings.siteParts?.prefooter?.list?.length * 2 * 250}px`"
      >
        <template v-for="itema in 2">
          <NuxtLink
            class="slide mx-8"
            v-for="(item, index) in settings.siteParts?.prefooter?.list"
            :to="item?.url"
            target="_BLANK"
            :key="index"
          >
            <Picture
              :disableParallax="true"
              :alt="item.picture.alt"
              :isLazyloaded="item.picture.isLazyloaded"
              :refImage="item.picture.refImage._ref"
            />
          </NuxtLink>
        </template>
      </div>
    </div> -->

    <!-- Links -->
    <div class="container xl:container mx-auto pt-6 pb-2 lg:py-6">
      <div class="grid-main">
        <!-- Logo -->
        <div class="col-start-1 col-end-13 md:col-end-5 flex justify-center md:justify-start">
          <NuxtLink
            :to="localePath('/')"
            aria-label="Home Page"
          >
            <SVGLoader
              name="logo"
              class="w-[174px] h-[102px] lg:h-[121px] lg:w-auto"
            />
          </NuxtLink>
        </div>

        <!-- Summer period -->
        <div
          class="col-start-1 col-end-6 md:col-start-5 md:col-end-9 flex flex-col justify-center mt-6 md:mt-0"
        >
          <p class="paragraph font-bold text-green uppercase mb-1">
            {{ $text('footer-summer-title') }}
          </p>
          <p class="paragraph">
            {{ settings.siteParts?.prefooter?.summerSeasonLength }}
          </p>
        </div>

        <!-- Winter period -->
        <div
          class="col-start-7 md:col-start-9 col-end-13 flex flex-col justify-center mt-6 md:mt-0"
        >
          <p class="paragraph font-bold text-green uppercase mb-1">
            {{ $text('footer-winter-title') }}
          </p>
          <p class="paragraph">
            {{ settings.siteParts?.prefooter?.winterSeasonLength }}
          </p>
        </div>

        <!-- Address Block -->
        <div
          class="col-start-1 col-end-13 md:col-start-1 md:col-end-4 lg:col-start-1 lg:col-end-5 flex items-start justify-start lg:justify-normal h-fit mt-6 lg:pl-1"
        >
          <MenuAddressBlock />
        </div>

        <!-- Nav 1 -->
        <div
          class="col-start-1 col-end-6 md:col-start-4 md:col-end-6 lg:col-start-5 lg:col-end-7 mt-6"
        >
          <Navigation
            class=""
            menuId="column-1-footer"
          />
        </div>

        <!-- Nav 2 -->
        <div
          class="col-start-6 col-end-13 md:col-start-6 md:col-end-8 lg:col-start-7 lg:col-end-9 mt-6"
        >
          <Navigation
            class=""
            menuId="column-2-footer"
          />
        </div>

        <!-- Nav 3 -->
        <div
          class="col-start-1 col-end-6 md:col-start-8 md:col-end-11 lg:col-start-9 lg:col-end-12 mt-6"
        >
          <NuxtLink
            class="capitalize"
            v-for="(item, index) in settings.socialMedia"
            :key="index"
            :to="item.url"
            >{{ item.type }}</NuxtLink
          >
        </div>

        <!-- YouTrust widget -->
        <div
          class="col-start-6 col-end-12 md:col-start-11 md:col-end-13 lg:col-start-12 lg:col-end-13 mt-6 flex justify-center lg:justify-end"
        >
          <!-- TrustYou Widget -->
          <iframe
            title="TrustYou Widget"
            class="w-[133px] lg:w-[103px] h-auto"
            :src="`https://api.trustyou.com/hotels/84d594a2-27de-4fbc-a1c3-44899ce48f04/seal.html?key=f71deae5-97fe-4261-9ce0-509fac13795b&lang=${locale}&size=l&scale=100`"
            allowtransparency="true"
            scrolling="no"
            frameborder="0"
          ></iframe>
          <!-- /TrustYou Widget -->
        </div>
      </div>
      <div class="mt-3">
        <div class="w-full border-t border-[#9E9E9E]"></div>
      </div>
      <div class="container flex justify-around pt-4 items-center px-0 lg:px-13 flex-wrap">
        <NuxtLink
          class="mx-1 w-[100px]"
          v-for="(item, index) in settings.siteParts?.prefooter?.list"
          :to="item?.url"
          target="_BLANK"
          :key="index"
        >
          <Picture
            :disableParallax="true"
            :alt="item.picture.alt"
            :isLazyloaded="item.picture.isLazyloaded"
            :refImage="item.picture.refImage._ref"
          />
        </NuxtLink>
      </div>
      <div
        v-if="settings.siteParts?.prefooter?.list2.length > 0"
        class="container flex justify-around pt-4 items-center px-0 lg:px-13 flex-wrap"
      >
        <NuxtLink
          class="mx-1 w-[80px]"
          v-for="(item, index) in settings.siteParts?.prefooter?.list2"
          :to="item?.url"
          target="_BLANK"
          :key="index"
        >
          <Picture
            :disableParallax="true"
            :alt="item.picture.alt"
            :isLazyloaded="item.picture.isLazyloaded"
            :refImage="item.picture.refImage._ref"
          />
        </NuxtLink>
      </div>
      <!-- <div class="">
        <div class="w-full border-t border-[#93A1AF] opacity-50"></div>
      </div> -->
      <div
        class="group max-w-[52px] ml-auto aspect-square cursor-pointer border-[#93A1AF] border-[0.2px] rounded-full flex lg:hidden items-center justify-center px-3"
        @click="scrollToTop"
      >
        <!-- <SVGLoader
              name="arrow-scroll-top"
              class="group-hover:-translate-y-[2px] transition-all duration-200"
            /> -->
        <span>
          <svg
            width="12"
            height="32"
            viewBox="0 0 12 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="group-hover:-translate-y-[3px] transition-all duration-200"
              d="M5.94336 2.34774L5.94336 26.396"
              stroke="black"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.94336 2.34774L5.94336 26.396"
              stroke="black"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              class="group-hover:-translate-y-[3px] transition-all duration-200"
              d="M11 5.99951L6 0.999511L1 5.99951"
              stroke="black"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
    </div>

    <!-- Sub Footer -->
    <div class="container bg-[#F7F7F7] py-4">
      <div class="grid-main lg:mx-auto">
        <!-- Legal -->
        <p
          class="text-[10px] col-start-1 col-end-8 lg:col-end-4 xl:col-end-5 uppercase mb-1 lg:my-auto"
        >
          © {{ new Date().getFullYear() }} Waldhaus Sils
        </p>

        <div class="col-start-1 col-end-12 lg:col-start-4 xl:col-start-5 lg:col-end-8 flex">
          <NuxtLink
            :to="`${locale !== 'de' ? '/' + locale : ''}/${settings.legal?.privacy?.url.fullPath}`"
            class="!mb-0 mr-4 xl:mr-4 2xl:mr-8 hover:underline"
            >{{ $text('privacy-policy') }}</NuxtLink
          >
          <NuxtLink
            :to="`${locale !== 'de' ? '/' + locale : ''}/${
              settings.legal?.conditions?.url.fullPath
            }`"
            class="!mb-0 mr-4 xl:mr-4 2xl:mr-8 hover:underline"
            >{{ $text('conditions') }}</NuxtLink
          >
          <NuxtLink
            :to="`${locale !== 'de' ? '/' + locale : ''}/${settings.legal?.imprint?.url.fullPath}`"
            class="!mb-0 mr-4 xl:mr-4 2xl:mr-8 hover:underline"
            >{{ $text('imprint') }}</NuxtLink
          >
        </div>

        <div
          class="col-start-1 col-end-13 lg:col-start-9 lg:col-end-13 flex justify-end mt-0 lg:mt-0"
        >
          <LanguageSwitch
            class="hidden lg:block min-w-[150px] h-[20px] lg:h-[56px] mr-2"
            theme="dark"
            id="footer-language-switch"
          />
          <div class="hidden lg:block">
            <Button
              class="!w-[97px] mr-2"
              variant="primary"
              variantType="normal"
              :to="bookingButtonUrl"
              target="_blank"
              >{{ $text('booking-button') }}</Button
            >
          </div>
          <div
            class="hidden group aspect-square cursor-pointer border-[#93A1AF] border-[0.2px] rounded-full lg:max-w-[56px] lg:max-h-[56px] lg:flex items-center justify-center px-3 lg:px-3 lg:py-1"
            @click="scrollToTop"
          >
            <!-- <SVGLoader
              name="arrow-scroll-top"
              class="group-hover:-translate-y-[2px] transition-all duration-200"
            /> -->
            <span>
              <svg
                width="12"
                height="32"
                viewBox="0 0 12 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="group-hover:-translate-y-[3px] transition-all duration-200"
                  d="M5.94336 2.34774L5.94336 26.396"
                  stroke="black"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.94336 2.34774L5.94336 26.396"
                  stroke="black"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  class="group-hover:-translate-y-[3px] transition-all duration-200"
                  d="M11 5.99951L6 0.999511L1 5.99951"
                  stroke="black"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';

const { $fullpath, $text } = useSiteSettings();
const settingsStore = useSettingsStore();
const { siteParts } = storeToRefs(settingsStore);
const { locale } = useI18n();

// Computed properties
const settings = computed(() => settingsStore);

// Methods
const scrollToTop = () => {
  if (process.server) return;

  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const bookingButtonUrl = computed(() => {
  return siteParts.value?.general?.bookingEngineLink?.isExternal
    ? siteParts.value?.general?.bookingEngineLink?.externalLink
    : $fullpath(siteParts.value?.general?.bookingEngineLink?.link._ref || '');
});
</script>
<style lang="postcss">
#footer {
  ul {
    @apply list-none pl-0 my-0;
  }
  a {
    @apply !no-underline mb-2 flex items-center;
    font-size: fluid(14, 14);
    line-height: fluid(19, 19);
    font-weight: normal;
    * {
      font-size: fluid(14, 14);
      line-height: fluid(19, 19);
      font-weight: normal;
    }
    &:last-child {
      @apply mb-0;
    }

    &:hover {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 5));
    }
  }

  .address-block {
    * {
      @apply text-black;
    }
    svg {
      path {
        @apply stroke-green;
      }
    }
  }

  .slider {
    @apply h-[200px] m-auto overflow-hidden relative w-full flex items-center 3xl:max-w-[1300px];

    &::before,
    &::after {
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      height: 100%;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      animation: scroll 30s linear infinite;
      display: flex;
    }

    .slide {
      height: 100px;
      width: 250px;
    }
  }
}
</style>
